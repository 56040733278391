<template>
	<v-app>
		<v-container fluid fill-height>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md6>
					<v-card class="container px-5 align-center mt-5">
						<v-card-title class="headline">
							{{ is_consent == 1 ? "User Consent" : "Thank You" }}</v-card-title
						>
						<v-card-text v-if="is_consent == 1">
							<p>
								By giving your consent, you agree to allow our platform to collect, process, and store your
								personal information in accordance with our privacy policy.
							</p>
							<p>This includes but is not limited to:</p>

							<ul>
								<li>Your name and contact information</li>
								<li>Your usage data on our platform</li>
								<li>Information necessary for providing our services</li>
							</ul>
							<p>You can withdraw your consent at any time by contacting our support team.</p>
						</v-card-text>
						<v-card-text v-else-if="!consent == 1">
							<p>You have already given your consent. You can close this window.</p>
						</v-card-text>
						<v-card-actions v-if="is_consent == 1">
							<v-spacer></v-spacer>
							<v-btn
								depressed
								tile
								:disabled="loading"
								:loading="loading"
								color="v-application v-chip green text-white"
								@click="showConsentDialog"
							>
								Give Consent
							</v-btn>
							<v-btn
								depressed
								tile
								:disabled="loading"
								color="error"
								class="v-application red"
								@click="declineConsent"
							>
								Decline
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>

		<Dialog :dialog="consentDialog" dense>
			<template v-slot:title>
				<p class="mb-0">Are you sure you want to give consent?</p>
			</template>
			<template v-slot:action>
				<div>
					<v-btn
						:disabled="loading"
						:loading="loading"
						color="blue darken-4 white--text"
						class="mr-2"
						@click="giveConsent(2)"
						tile
						depressed
					>
						Yes! Give Consent
					</v-btn>
					<v-btn :disabled="loading" tile depressed @click="consentDialog = false"> No! Cancel </v-btn>
				</div>
			</template>
		</Dialog>
	</v-app>
</template>

<script>
import { POST } from "@/core/services/store/request.module";
import Dialog from "@/view/components/Dialog";
import { mapGetters } from "vuex";

export default {
	name: "ConsentPage",
	components: {
		Dialog,
	},
	computed: {
		...mapGetters(["errors", "messages"]),
	},
	data() {
		return {
			is_consent: null,
			askConsentDialog: false,
			loading: false,
			userId: null,
			consentDialog: false,
		};
	},
	mounted() {
		const urlParams = new URLSearchParams(window.location.search);
		this.userId = urlParams.get("oauth");
		if (!this.userId) {
			console.error("User ID not found in URL");
		}
	},
	created() {
		this.is_consent = this.$route.meta.consentData.is_consent;
	},
	methods: {
		showConsentDialog() {
			this.consentDialog = true;
		},
		giveConsent(value) {
			if (!this.userId) {
				console.error("User ID not found");
				return;
			}
			this.loading = true;
			this.$store
				.dispatch(POST, {
					url: `/give-consent`,
					data: { userId: this.userId, consent: value },
				})
				.then((response) => {
					this.askConsentDialog = false;
					// this.message = response.message;
					console.log(response);
					// this.changeSuccess	Message("Consent given successfully");
					this.consentDialog = false;
					this.is_consent = 2;
				})

				.catch((error) => {
					this.error = error;
					console.log(error);
					console.error("Error giving consent:", error);
					this.changeErrorMessage("Error giving consent");
				})
				.finally(() => {
					this.loading = false;
				});
		},
		declineConsent() {
			this.giveConsent(3);
			this.askConsentDialog = false;
			window.close();
		},
	},
};
</script>

<style>
.v-application .red {
	background-color: #f44336 !important;
	border-color: #f44336 !important;
}
.v-application .v-chip__content {
	width: fit-content;
	/* padding: 5px !important;
	font-size: 13px !important; */
	display: inline-flex;
	height: 100%;
	max-width: 100%;
}
</style>
